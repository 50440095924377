<template>
    <div class="position-relative">
        <h5>You won the CP Points Daily Drawing!</h5>
        <p>
            {{data.winning_text}}
        </p>
        <p>
            Drawing entry date: {{data.lottery_date}}
        </p>
        <div v-if="data.previous_day_lottery_winner_name && data.previous_day_lottery_winner_state" class="winner-badge mb-3">
            <p>Yesterday's winner: {{ data.previous_day_lottery_winner_name }}, {{ data.previous_day_lottery_winner_state}}</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-5 col-md-4">
                <slot name="closeModal"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import api from "../api/rewardwheel.js";

export default {
    props:{
        data: {}
    }
}
</script>

<style>
.close-modal{
    position: absolute;
    top: 0;
    right: 0;
}
.winner-badge{
    background-color: #ebebeb;
    border-radius: 5px;
    padding: 3px 10px;
    font-weight: 500;
}
.winner-badge p{
    margin: 0;
}
</style>