<template>
  <div>
    <div class="row">
      <!--Code for ToggleDrawer-->
      <div class="col-2 align-self-center" slot="content">
        <a href="javascript:void(0)" @click="clickBack">
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 31.494 31.494"
            style="enable-background: new 0 0 31.494 31.494; margin-top: 30px"
            xml:space="preserve"
            width="20"
          >
            <path
              style="fill: #1e201d"
              d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
	c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
	c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"
            />
          </svg>
        </a>
      </div>
      <div class="col-8 align-self-end">
        <label class="heading-termsandCondition"
          ><b>Merchant Details</b></label
        >
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MerchantDetailsHeader",
  data: () => ({
    open: true,
    showmenuicon: true,
  }),
  components: {},
  mounted() {
    this.$root.$emit("loginapp", [""]);
    this.$root.$on("header closed", function (data) {
      console.log("close drawer", this.showmenuicon);
    });
  },
  methods: {
    clickBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
#container {
  height: 100px !important;
}
</style>
