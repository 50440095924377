import VueFirestore from 'vue-firestore';
import Firebase from 'firebase'
import Vue from 'vue'

require('firebase/firestore')

Vue.use(VueFirestore)


var config = {
    apiKey: process.env.VUE_APP_FIREBASE_API,
    authDomain: process.env.VUE_APP_AUTO_DOMAIN,
    databaseURL: process.env.VUE_APP_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDERID,
    appId: process.env.VUE_APP_APPID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
}
var firebaseApp = Firebase.initializeApp(config);

export const db = firebaseApp.firestore();

// date issue fix according to firebase
const settings = {
    //timestampsInSnapshots: true
};


db.settings(settings);
export default {
    name: "db",
};