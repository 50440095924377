const refreshAccountBalance = () => {
    return new Promise((res, rej) => {
        axios.get('refreshaccountbalance')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getUserdetails = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.get("userProfile")
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const sendVerificationCode = (request) => {
    return new Promise((res, rej) => {
        axios.post('sendverificationcode', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const verifyVerificationCode = (request) => {
    return new Promise((res, rej) => {
        axios.post('verifyverificationcode', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const updateNewEmail = (request) => {
    return new Promise((res, rej) => {
        axios.post('updateemail', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const sendOtpyNewPhone = (request) => {
    return new Promise((res, rej) => {
        axios.post('sendotptonewnumber', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const verifyNewPhone = (request) => {
    return new Promise((res, rej) => {
        axios.post('verifyotpfornewphone', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const saveDetails = (request) => {
    return new Promise((res, rej) => {
        axios.post('update/userProfile', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const changeBank = (request) => {
    return new Promise((res, rej) => {
        axios.get('changeBankAccount', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const directLinkGenerate = (request) => {
    return new Promise((res, rej) => {
        axios.get('directlinkgenerate', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const directLinkBank = (request) => {
    return new Promise((res, rej) => {
        axios.post('directlinkbank', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const updateLinkGenerate = (request) => {
    return new Promise((res, rej) => {
        axios.get('updatelinkgenerate', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const updateBank = (request) => {
    return new Promise((res, rej) => {
        axios.post('updatebank', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const storeManualBankDetails = (request) => {
    return new Promise((res, rej) => {
        axios.post('storemanualbankdetails', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const consumerdeactivate = (request) => {
    return new Promise((res, rej) => {
        axios.post('deactivate', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getBankAccountList = (request) => {
    return new Promise((res, rej) => {
        axios.post("getbanklist", request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getUserBankAccountList = (request) => {
    return new Promise((res, rej) => {
        axios.post('getuserbanklist', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const saveBankDetailsFromList = (request) => {
    return new Promise((res, rej) => {
        axios.post('savebanklist', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const updateAccountNumber = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.post('updatebankaccountno', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};


const updateDateOfBirth = (request) => {
    return new Promise((res, rej) => {
        axios.post('updatedateofbirth', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const changePIN = (request) => {
    return new Promise((res, rej) => {
        axios.post('changepin', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const generateConnectFix = (request) => {
    return new Promise((res, rej) => {
        axios.post('authgenerateconnectfixurl', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const checkBankAccountState = () => {
    return new Promise((res, rej) => {
        axios.get('checkbankaccountstate')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const uploadDocument = (request) => {
    // var config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return new Promise((res, rej) => {
        axios.post('/uploadconsumerdocument', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const checkUserRequriedDocumentStatus = (request) => {
    return new Promise((res, rej) => {
        axios.post('checkuserrequrieddocumentstatus')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const disableBankAccount = (request) => {
    return new Promise((res, rej) => {
        axios.post('disablebankaccount', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const disabledAccounts = () => {
    return new Promise((res, rej) => {
        axios.post('/getdisabledbanklist')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const enableBankAccount = (request) => {
    return new Promise((res, rej) => {
        axios.post('enablebankaccount', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getFinancialInstitutions = () => {
    return new Promise((res, rej) => {
        axios.post('/getfinancialinstitutions')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getPendingTransactionReturns = (request) => {
    return new Promise((res, rej) => {
        axios.post('/getpendingtransactionreturns', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const deleteFinancialInstitution = (request) => {
    return new Promise((res, rej) => {
        axios.post('/removeinstitution', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const checkManualAvailableForThisConsumer = () => {
    return new Promise((res, rej) => {
        axios.get('/checkprobalereturnexists')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const checkMicrobiltErrorExists = () => {
    return new Promise((res, rej) => {
        axios.get('/checkmicrobilterrorexistsformanualbank')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const representReturnTransaction = (request) => {
    return new Promise((res, rej) => {
        axios.post('/representreturntransaction', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const checkForRealAccountNumber = (request) => {
    return new Promise((res, rej) => {
        axios.post('/checkforrealaccountnumber', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getManualBankAccountList = (request) => {
    return new Promise((res, rej) => {
        axios.get("getmanualbankaccountlist", request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const addReturnTransactionPaymentSchedule = (request) => {
    return new Promise((res, rej) => {
        axios.post("addreturntransactionpaymentschedule", request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const updateRealAccountNumber = (request) => {
    return new Promise((res, rej) => {
        axios.post('/saverealaccountnumber', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const uploadDocumentToAws = (request) => {
    var config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return new Promise((res, rej) => {
        axios.post('/uploadconsumerdocumenttoaws', request, config)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const dontShowUploadBankStatement = () => {
    return new Promise((res, rej) => {
        axios.post('/dontshowuploadbankstatement')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getFrontendVisibilityBank = (request) => {
    return new Promise((res, rej) => {
        axios.post('/getfrontendvisibilitybank', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const searchBank = (request) => {
    return new Promise((res, rej) => {
        axios.post('/searchbank', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const findBank = (request) => {
    return new Promise((res, rej) => {
        axios.post('/findbank', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const revokeAkoyaToken = (request) => {
    return new Promise((res, rej) => {
        axios.post('/revokeakoyatoken', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const revokeUserAkoyaToken = (request) => {
    return new Promise((res, rej) => {
        axios.post('/revokeuserakoyatoken', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getMxLinkedAccounts = (request) => {
    return new Promise((res, rej) => {
        axios.post('/getmxlinkedaccounts', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const searchRoutingNumber = (request) => {
    return new Promise((res, rej) => {
        axios.post('/searchroutingnumber', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getExistingFinicityBankingSolutionData = (request) => {
    return new Promise((res, rej) => {
        axios.post('/finicitybankingsolutiondata', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const consumerBankFailureReport = (request) => {
    return new Promise((res, rej) => {
        axios.post('/consumerbankfailurereport', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const openMxLink = (request) => {
    return new Promise((res, rej) => {
        axios.get('openmxlink', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const callaccountownerapi = (request) => {
    return new Promise((res, rej) => {
        axios.post('callaccountownerapi', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const skipBankReLink = (request) => {
    return new Promise((res, rej) => {
        axios.post('/skipbankrelink', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const manualLinkWithSameAccount = (request) => {
    return new Promise((res, rej) => {
        axios.post('/manuallinkwithsameaccount', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const logAkoyaError = (request) => {
    return new Promise((res, rej) => {
        axios.post('/logakoyaerror', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const switchBankFromList = (request) => {
    return new Promise((res, rej) => {
        axios.post('switchbankfromlist', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const directLinkGenerateForMx = (request) => {
    return new Promise((res, rej) => {
        axios.post('mxfixaccountgenerateurl', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const fixMxConnection = (request) => {
    return new Promise((res, rej) => {
        axios.post('fixmxconnection', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

export default {
    refreshAccountBalance,
    getUserdetails,
    sendVerificationCode,
    verifyVerificationCode,
    updateNewEmail,
    verifyNewPhone,
    sendOtpyNewPhone,
    saveDetails,
    changeBank,
    storeManualBankDetails,
    consumerdeactivate,
    getBankAccountList,
    getUserBankAccountList,
    saveBankDetailsFromList,
    directLinkGenerate,
    directLinkBank,
    updateLinkGenerate,
    updateBank,
    updateAccountNumber,
    updateDateOfBirth,
    changePIN,
    generateConnectFix,
    checkBankAccountState,
    uploadDocument,
    checkUserRequriedDocumentStatus,
    disableBankAccount,
    disabledAccounts,
    enableBankAccount,
    getFinancialInstitutions,
    getPendingTransactionReturns,
    deleteFinancialInstitution,
    checkManualAvailableForThisConsumer,
    checkMicrobiltErrorExists,
    representReturnTransaction,
    getManualBankAccountList,
    addReturnTransactionPaymentSchedule,
    checkForRealAccountNumber,
    updateRealAccountNumber,
    uploadDocumentToAws,
    dontShowUploadBankStatement,
    getFrontendVisibilityBank,
    searchBank,
    findBank,
    revokeAkoyaToken,
    revokeUserAkoyaToken,
    getMxLinkedAccounts,
    searchRoutingNumber,
    consumerBankFailureReport,
    getExistingFinicityBankingSolutionData,
    openMxLink,
    callaccountownerapi,
    skipBankReLink,
    manualLinkWithSameAccount,
    logAkoyaError,
    switchBankFromList,
    directLinkGenerateForMx,
    fixMxConnection
};