<template>
  <div class="container">
    <div class="row">
      <!--Code for ToggleDrawer-->
      <!--Code for CanPay Logo-->

      <div
        class="col-2 content"
        slot="content"
        style="background-color: #149240; margin-top:40px;"
      >
        <a href="javascript:void(0)" @click="clickBack">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 125"
            style="enable-background:new 0 0 100 125;"
            xml:space="preserve"
            height="40"
            width="30"
            fill="#ffffff"
          >
            <g id="Layer_2"></g>
            <g id="Layer_1">
              <path
                d="M12.1,47.1C12.1,47.1,12.1,47.1,12.1,47.1l29.5-29.5c1.6-1.6,4.2-1.6,5.8,0c1.6,1.6,1.6,4.2,0,5.8L24.9,45.9h61.8
		c2.3,0,4.1,1.8,4.1,4.1s-1.8,4.1-4.1,4.1H24.9l22.5,22.5c1.6,1.6,1.6,4.2,0,5.8c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2
		L12.1,52.9c0,0,0,0,0,0L9.2,50L12.1,47.1z"
              />
            </g>
          </svg>

          <!-- <i class="fa fa-3x fa-angle-left icon"  style="color: #000000" aria-hidden="true" v-show="!showmenuicon"></i> -->
        </a>
      </div>
      <div class="col-6"></div>
      <div class="col-4">
        <button class="btn-save btn-save-padding" @click="clickSave"> Save </button>
      </div>
    </div>
  </div>
</template>

<script>
import DrawerLayout from "vue-drawer-layout";
export default {
  name: "EditProfileHeader",
  components: {
    DrawerLayout,
  },

  data: () => ({
    open: true,
    showmenuicon: true,
  }),
  mounted() {
    let self = this;
    self.$root.$on("Menu Drawer Close", function(data) {
      self.showmenuicon = true;
    });

    self.$root.$on("Menu Drawer Open", function(data) {
      setTimeout(function() {
        self.showmenuicon = false;
      }, 30);
    });
  },

  methods: {

    clickBack() {
                  this.$router.push("/profile");
     // this.$root.$emit("edit profile back clicked", [""]);
    },
    clickSave(){
      this.$root.$emit("edit profile save clicked", [""]);

    }
  },
};
</script>
