<template>
    <div class="position-relative">
        <h5>Reward Points Exchange Rate Adjustment</h5>
        <p>
            We’ve updated the Exchange Rate for points to {{redeem_points}} points per ${{redeem_points * exchange_rate}}. Rest assured that your existing points have been adjusted to ensure that you carry over the same benefit in points that you had prior to this recent adjustment. Thanks for making CanPay Even More Rewarding.
        </p>
        <div class="row justify-content-center">
            <div class="col-5 col-md-4">
                <slot name="closeModal"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import api from "../api/rewardwheel.js";

export default {
    props:{
    },
    data(){
        return{
            exchange_rate:  parseFloat(process.env.VUE_APP_REWARD_EXCHANGE_RATE),
            redeem_points:  parseFloat(process.env.VUE_APP_REWARD_MINIMUM_REDEEM_POINTS)
        }
    }
}
</script>

<style>
.close-modal{
    position: absolute;
    top: 0;
    right: 0;
}
</style>