<template>
  <div class="container h-100">
    <div class="h-100 position-relative">
        <a class="td-header-link" href="javascript:void(0)" @click="backToPreviousPage">
          <svg fill="#000" width="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.78 19.03a.75.75 0 01-1.06 0l-6.25-6.25a.75.75 0 010-1.06l6.25-6.25a.75.75 0 111.06 1.06L5.81 11.5h14.44a.75.75 0 010 1.5H5.81l4.97 4.97a.75.75 0 010 1.06z"/></svg>
        </a>
        <div class="td-header-card">
          <h2 class="td-header-title">Transaction Details</h2>
        </div>
    </div>
  </div>
</template>

<script>
import DrawerLayout from "vue-drawer-layout";

export default {
  name: "Header",
  components: {
    DrawerLayout
  },
  data: () => ({
    open: true,
    showmenuicon: true,
  }),
  mounted() {
    let self = this;
    self.$root.$on("Menu Drawer Close", function(data) {
      self.showmenuicon = true;
    });

    self.$root.$on("Menu Drawer Open", function(data) {
      setTimeout(function() {
        self.showmenuicon = false;
      }, 30);
    });
  },

  methods: {
    backToPreviousPage() {
      history.back()
    }
  }
};
</script>

<style>
.td-header-link{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}
.td-header-card{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.td-header-title{
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
</style>
