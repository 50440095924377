function detectPlatform() {
    const userAgent = navigator.userAgent;

    if (/mobile/i.test(userAgent)) {
        return {
            isMobile: true,
            isWeb: false,
            isTablet: false
        };
    } else if (/tablet/i.test(userAgent)) {
        return {
            isMobile: false,
            isWeb: false,
            isTablet: true
        };
    } else {
        return {
            isMobile: false,
            isWeb: true,
            isTablet: false
        };
    }
}

const platform = {
    install(Vue) {
        Vue.prototype.$platform = detectPlatform();

        window.addEventListener("resize", () => {
            const newPlatform = detectPlatform();

            if (newPlatform !== Vue.prototype.$platform) {
                Vue.prototype.$platform = newPlatform;
            }
        });

        window.addEventListener("orientationchange", () => {
            const newPlatform = detectPlatform();

            if (newPlatform !== Vue.prototype.$platform) {
                Vue.prototype.$platform = newPlatform;
            }
        });
    }
};

export default platform;
