<template>
  <div>
    <div class="row">
      <!--Code for ToggleDrawer-->
      <div class="col-2 content" slot="content" style="z-index: 999999;  background-color: #ffffff;">
        <a href="javascript:void(0)" @click="showDrawer">
          <i
            class="fa fa-2x fa-bars icon menuicon-termsandcondition-style"
            aria-hidden="true"
            v-show="showmenuicon"
          ></i>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 2540 3175"
            style="enable-background:new 0 0 2540 3175;  margin-top:30px;"
            xml:space="preserve"
            height="30"
            width="30"
             v-show="!showmenuicon"
          >
            <g>
              <path
                d="M1878,2270c39,39,39,104,0,143c-40,40-104,40-144,0L662,1342c-39-40-39-104,0-144L1734,127c40-40,104-40,144,0
		c39,39,39,104,0,143L878,1270L1878,2270z"
              />
            </g>
          </svg>
        </a>
      </div>
      <div class="col-8">
        <label class="menu-icon heading-termsandCondition float-left"><b>{{ $route.name == 'rewardwheeltermsandconditions' ? 'Reward wheel' : '' }} Terms and Conditions</b></label>
      </div>
      <!--Code for CanPay Logo-->
      <div class="col-2 text-right" id="col-logo-header" style=" margin-left: -52px;">
        <img src="../../../assets/images/canpay-logo-new.png" height="50" />
      </div>
    </div>
  </div>
</template>

<script>
import DrawerLayout from "vue-drawer-layout";
export default {
  name: "TermsandConditionsHeader",
  data: () => ({
    open: true,
    showmenuicon: true,
  }),
  components: {
    DrawerLayout,
  },
  mounted() {
            this.$root.$emit("loginapp", [""]);
     let self = this;
       self.$root.$on("Menu Drawer Close", function(data) {
                       self.showmenuicon = true;
      
    });

   self.$root.$on("Menu Drawer Open", function(data) { 
       setTimeout(function() {
     self.showmenuicon = false;
        }, 30);
      
    });
  },
 methods: {
     showDrawer() {
             this.showmenuicon = false;
      this.$root.$emit("Menu Drawer", [""]);
    },
  },
};
</script>
<style scoped>

#container {
  height: 100px !important
}

#row-header{
height: 100px !important
}

#col-header{
margin-top:20px;
}
#col-logo-header {
margin-top:15px;
}
</style>