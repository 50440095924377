function authenticate(to, from, next) {
    var isAuthenticated = false;
    //this is just an example. You will have to find a better or 
    // centralised way to handle you localstorage data handling 
    if (localStorage.getItem('consumer_token') != null)
        isAuthenticated = true;
    else
        isAuthenticated = false;
    if (isAuthenticated) {
        next(); // allow to enter route
    } else {
        if (to.name == 'dashboard' || to.name == 'profile' || to.name == 'privacypolicy' || to.name == 'termsandconditions' || to.name == 'BankLinkingOption') {
            next({ name: 'login' });
        } else {
            next({ name: 'login', query: { redirect: to.fullPath } });
        }
    }
}

function isAllowedRewardWheel(to, from, next) {

    var isAuthenticated = false;
    //this is just an example. You will have to find a better or 
    // centralised way to handle you localstorage data handling 
    if (localStorage.getItem('consumer_token') != null)
        isAuthenticated = true;
    else
        isAuthenticated = false;
    if (isAuthenticated) {

        const consumer_login_response = JSON.parse(localStorage.getItem("consumer_login_response"));
        var rwState = consumer_login_response.rw_state
        var rwInvited = consumer_login_response.rw_invited

        if (rwState == 'allowed') {
            if (rwInvited == 1) {
                next({ path: 'pay' });
            } else {
                next(); // allow to enter route
            }
        } else {
            next({ path: 'pay' });
        }

        next(); // allow to enter route
    } else {
        next({ name: 'login', query: { redirect: to.fullPath } });
    }
}

function isAllowedRewardWheelPartial(to, from, next) {

    var isAuthenticated = false;
    //this is just an example. You will have to find a better or 
    // centralised way to handle you localstorage data handling 
    if (localStorage.getItem('consumer_token') != null)
        isAuthenticated = true;
    else
        isAuthenticated = false;
    if (isAuthenticated) {

        const consumer_login_response = JSON.parse(localStorage.getItem("consumer_login_response"));
        var rwState = consumer_login_response.rw_state
        var rwInvited = consumer_login_response.rw_invited

        if (rwState == 'allowed' || rwState == 'partially_allowed') {
            if (rwInvited == 1) {
                next({ path: 'pay' });
            } else {
                next(); // allow to enter route
            }
        } else {
            next({ path: 'pay' });
        }

        next(); // allow to enter route
    } else {
        next({ name: 'login', query: { redirect: to.fullPath } });
    }
}

const routes = [{
        path: "/",
        redirect: "/login",
        name: "Login"
    },
    // All undefined routes are redirected to page 404
    {
        path: '*',
        redirect: '/pay'
    },
    {
        path: "/splash",
        name: "Splash",
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Login/Splash.vue')
    },
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Login/Login.vue')
    },
    {
        path: '/enterunlockpin/:email/:otp/:phone',
        name: 'enterUnlockPin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Login/VerifyLinkOtp.vue')
    },
    {
        path: '/registration',
        name: 'EnterNamePhone',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Registration/EnterNamePhone.vue')
    },

    {
        path: '/upgradecanpaywithemail',
        name: 'UpgradeCanpayWithEmail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/UpgradeCanpayWithEmail.vue')
    },

    {
        path: '/enterconsumeraddress',
        name: 'EnterConsumerAddress',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/EnterConsumerAddress.vue')
    },

    {
        path: '/uploadconsumerdocument',
        name: 'UploadConsumerDocument',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/UploadConsumerDocument.vue')
    },
    {
        path: '/quickaccesspinset/:sessionID/quickaccesspinset',
        name: 'QuickAccessPinSet',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/QuickAccessPinSet.vue')
    },
    {
        path: '/emailverificationcode/:email?/:verificationCode?/:sessionId?',
        name: 'EmailVerificationCode',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/EmailVerificationCode.vue')
    },
    {
        path: '/existingphonenumberverification/:sessionID/existingphonenumberverification',
        name: 'ExistingPhoneNumberVerification',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/ExistingPhoneNumberVerification.vue')
    },
    {
        path: '/primaryphoneNumberregistrationsuccess/:phone/:sessionID/primaryphoneNumberregistrationsuccess',
        name: 'PrimaryPhoneNumberRegistrationSuccess',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/PrimaryPhoneNumberRegistrationSuccess.vue')
    },
    {
        path: '/phonenumberverificationcode/:phone/:sessionID/phonenumberverificationcode',
        name: 'phonenumberverificationcode',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/PhoneNumberVerificationCode.vue')
    },
    {
        path: '/bankaccountregistration/:sessionID/bankaccountregistration',
        name: 'BankAccountRegistration',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/BankAccountRegistration.vue')
    },
    {
        path: '/registrationsuccess',
        name: 'registrationsuccess',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/BankRegistrationSuccess.vue')
    },
    {
        path: '/sessionID/duplicatemobilenumber',
        name: 'DuplicateMobileNumber',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/DuplicateMobileNumber.vue')
    },
    {
        path: '/existingphonenumberverificationsuccess/:phone/:sessionID/existingphonenumberverificationsuccess',
        name: 'ExistingPhoneNumberVerificationSuccess',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/ExistingPhoneNumberVerificationSuccess.vue')
    },
    {
        path: '/registerprimaryphonenumber/:sessionID/registerprimaryphonenumber',
        name: 'RegisterPrimaryPhoneNumber',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/RegisterPrimaryPhoneNumber.vue')
    },
    {
        path: '/onboringconnectbankaccount/:sessionID/onboringconnectbankaccount',
        name: 'OnboringConnectBankAccount',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Profile/BankLinkingOption.vue')
    },

    {
        path: '/onboardingmanualbankaccount/:sessionID/onboardingmanualbankaccount',
        name: 'OnboardingManualBankAccount',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Profile/BankLinkingOption.vue')
    },

    {
        path: '/termsandcondition/:email/:sessionID/termsandcondition',
        name: 'TermsAndCondition',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/TermsAndCondition.vue')
    },
    {
        path: '/artboart16',
        name: 'Artboard16',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/Artboard16.vue')
    },

    {
        path: '/artboart17',
        name: 'Artboard17',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/Artboard17.vue')
    },

    {
        path: '/onboardingsingin',
        name: 'OnboardingSingIn',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/OnboardingSingIn.vue')
    },

    {
        path: '/onboardingsigninios',
        name: 'OnboardingSigninIos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnBoardScreeen/OnboardingSigninIos.vue')
    },
    {
        path: '/onboardingprivacypolicy',
        name: 'OnboardingPrivacyPolicy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnboardingTemsAndCondition/OnboardingPrivacyPolicy.vue')
    },

    {
        path: '/onboardingtermsandcondition',
        name: 'OnboardingTermsAndCondition',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnboardingTemsAndCondition/OnboardingTermsAndCondition.vue')
    },
    {
        path: '/onboardingconsumerprivacynotice',
        name: 'OnboardingConsumerPrivacyNotice',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/OnboardingTemsAndCondition/OnboardingConsumerPrivacyNotice.vue')
    },

    {
        path: '/participatingmerchant',
        name: 'participatingmerchant',
        beforeEnter: authenticate,
        meta: { preserveState: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/ParticipatingMerchants/ParticipatingMerchant.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        beforeEnter: authenticate,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Payment/Dashboard.vue')
    },
    {
        path: '/account',
        name: 'Account',
        beforeEnter: authenticate,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Account/Account.vue')
    },
    {
        path: '/privacypolicy',
        name: 'privacypolicy',
        beforeEnter: authenticate,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/PrivacyPolicy/PrivacyPolicy.vue')
    },

    {
        path: '/termsandconditions',
        name: 'termsandconditions',
        beforeEnter: authenticate,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/TermsAndConditions/Terms&Conditions.vue')
    },
    {
        path: '/tip',
        name: 'tip',
        beforeEnter: authenticate,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Payment/Tip.vue')
    },

    {
        path: '/pay',
        name: 'pay',
        beforeEnter: authenticate,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Payment/Pay.vue')
    },

    {
        path: '/transactiondetails',
        name: 'transactiondetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/TransactionDetails.vue')
    },
    {
        path: '/merchantdetails',
        name: 'merchantdetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/ParticipatingMerchants/MerchantDetails.vue')
    },
    {
        path: '/forgotpassword',
        name: 'ForgotPassword',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/ForgotPassword/ForgotPassword.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        beforeEnter: authenticate,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Profile/Profile.vue')
    },
    {
        path: '/editprofile',
        name: 'EditProfile',
        beforeEnter: authenticate,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Profile/EditProfile.vue')
    },
    {
        path: '/incompleteaccount',
        name: 'InCompleteAccount',
        beforeEnter: authenticate,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Payment/InCompleteAccount.vue')
    },
    {
        path: '/enterforgotpin/:verificationCode?/:token?',
        name: 'EnterForgotPin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/ForgotPassword/EnterForgotPin.vue')
    },

    {
        path: '/locateretailer',
        name: 'LocateRetailer',
        component: () =>
            import ('../components/RetailerLocator/LocateRetailer.vue')
    },
    {
        path: '/retailerdetails',
        name: 'RetailerDetails',
        component: () =>
            import ('../components/RetailerLocator/RetailerDetails.vue')
    },
    {
        path: '/returns',
        name: 'ReturnTransactionDetails',
        beforeEnter: authenticate,
        component: () =>
            import ('../components/ReturnTransactionDetails.vue')
    },
    {
        path: '/updatedtransaction',
        name: 'UpdatedTransactionDetails',
        beforeEnter: authenticate,
        component: () =>
            import ('../components/UpdatedTransactionDetails.vue')
    },
    {
        path: '/acceptedtransaction',
        name: 'AcceptedTransactionDetails',
        beforeEnter: authenticate,
        component: () =>
            import ('../components/AcceptedTransactionDetails.vue')
    },
    {
        path: '/banklinking',
        name: 'BankLinkingOption',
        beforeEnter: authenticate,
        component: () =>
            import ('../components/Profile/BankLinkingOption.vue')
    },

    {
        path: '/setting',
        name: 'Setting',
        beforeEnter: authenticate,
        component: () =>
            import ('../components/Setting/Setting.vue')
    },
    {
        path: '/uploaddocument',
        name: 'UploadDocument',
        beforeEnter: authenticate,
        component: () =>
            import ('../components/Setting/UploadDocument.vue')
    },

    {
        path: '/banklisting',
        name: 'BankList',
        beforeEnter: authenticate,
        component: () =>
            import ('../components/Payment/BankList.vue')
    },
    {
        path: '/otp-verification/:email/:phone',
        name: 'OtpVerification',
        component: () =>
            import ('../components/ForgotPassword/OtpVerification.vue')
    },
    {
        path: '/disablebanklisting',
        name: 'DisableBankList',
        beforeEnter: authenticate,
        component: () =>
            import ('../components/Setting/DisableBankList.vue')
    },
    {
        path: '/financialinstitutions',
        name: 'FinancialInstitutions',
        beforeEnter: authenticate,
        component: () =>
            import ('../components/Setting/FinancialInstitutions.vue')
    },
    {
        path: '/historicaltransactiondetails',
        name: 'historicaltransactiondetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/HistoricalTransactionDetails.vue')
    },
    {
        path: '/returnbanklist',
        name: 'ReturnBankList',
        beforeEnter: authenticate,
        component: () =>
            import ('../components/Payment/ReturnBankList.vue')
    },
    {
        path: '/pendingtransactiondetails',
        name: 'pendingtransactiondetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/PendingTransactionDetails.vue')
    },
    {
        path: '/rewardWheel',
        name: 'RewardWheel',
        beforeEnter: isAllowedRewardWheel,
        component: () =>
            import ('../components/RewardWheel/Index.vue')
    },
    {
        path: '/rewardPoints',
        name: 'RewardPoints',
        beforeEnter: isAllowedRewardWheelPartial,
        component: () =>
            import ('../components/RewardWheel/RewardPoints.vue')
    },
    {
        path: '/rewardHistory',
        name: 'rewardHistory',
        beforeEnter: isAllowedRewardWheelPartial,
        component: () =>
            import ('../components/RewardHistory/Index.vue')
    },
    {
        path: '/lastThreeRewardHistory',
        name: 'LastThreeRewardHistory',
        beforeEnter: isAllowedRewardWheelPartial,
        component: () =>
            import ('../components/RewardHistory/LastThreeRewardHistory.vue')
    },
    // {
    //     path: '/rewardHistoryDetail',
    //     name: 'rewardHistoryDetail',
    //     beforeEnter: authenticate,
    //     component: () =>
    //         import ('../components/RewardHistory/View.vue')
    // },
    {
        path: '/pendingTransactionHistory',
        name: 'pendingTransactionHistory',
        beforeEnter: authenticate,
        component: () =>
            import ('../components/PendingTransactionDetails.vue')
    },
    {
        path: '/rewardwheeltermsandconditions',
        name: 'rewardwheeltermsandconditions',
        beforeEnter: authenticate,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/RewardWheel/Terms&conditions.vue')
    },
    {
        path: '/success',
        name: 'success',
        component: () =>
            import ('../components/Payment/BankLinkSuccess.vue')
    },
    {
        path: '/successbanklisting',
        name: 'successBankList',
        component: () =>
            import ('../components/Payment/SuccessBankList.vue')
    },
    {
        path: '/registrationcomple',
        name: 'registrationcomple',
        component: () =>
            import ('../components/Payment/RegistrationSuccess.vue')
    },
    {
        path: '/mx-success',
        name: 'mx-success',
        component: () =>
            import ('../components/Payment/MxSuccess.vue'),
        props: true, // If you want to pass route params as props
    },
    {
        path: '/linkabenefit',
        name: 'linkabenefit',
        beforeEnter: authenticate,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Sponsor/Sponsor.vue')
    },
    {
        path: '/sponsortransactionhistory',
        name: 'sponsortransactionhistory',
        beforeEnter: authenticate,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Sponsor/SponsorTransactionHistory.vue')
    },
    {
        path: '/memberbenefit',
        name: 'memberbenefit',
        beforeEnter: authenticate,
        component: () => 
            import ( /* webpackChunkName: "about" */ '../components/MemberBenefit.vue'),
        props: true
    }

]

export default routes