import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import routes from './router'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'
window.axios = require('axios');
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import VueRouter from 'vue-router';
import Toasted from 'vue-toasted';
import moment from 'moment';
import BootstrapVue from 'bootstrap-vue';
import DrawerLayout from 'vue-drawer-layout';
import * as VueGoogleMaps from "vue2-google-maps";
import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

import Vuex from 'vuex';
import store from './store';
Vue.use(Vuex);

import vSelect from "vue-select";

Vue.component("v-select", vSelect);

import VueOnlineProp from "vue-online-prop"
Vue.use(VueOnlineProp)

import VueIntercom from 'vue-intercom';

Vue.use(VueIntercom, { appId: 'itlulg08' });

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_KEY,
        libraries: "places" //necessary for places input
    }
});

// Platform Detect 
import platform from './platform';
Vue.use(platform);

//time count down for transaction modification
import VueCountdownTimer from 'vuejs-countdown-timer';
Vue.use(VueCountdownTimer);

import VueGtm from '@gtm-support/vue2-gtm';
Vue.use(VueGtm, {
    id: 'GTM-WNN2QRN', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    queryParams: {
        // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    },
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
});

Vue.use(DrawerLayout)

// you can also pass options, check options reference below
Vue.use(Toasted)
Vue.use(BootstrapVue);
Vue.use(VueGoogleAutocomplete);
Vue.use(VueRouter);
axios.defaults.baseURL = process.env.VUE_APP_API_URL; //base url for all apis
axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");

const reward_wheel_axios = axios.create({
    baseURL: process.env.VUE_APP_REWARD_WHEEL_URL
  })
  
  let isInternetConnected = true
  const pendingRequests = new Map()
  
  reward_wheel_axios.interceptors.request.use(
    config => {
      if (!isInternetConnected) {
        const cancelToken = new axios.CancelToken(cancel => {
          config.cancel = cancel
        })
        config.cancelToken = cancelToken
        throw new axios.Cancel('Please check your internet connection and try again')
      }
      if (isInternetConnected) {
        addPendingRequest(config)
      }
      return config
    },
    error => {
      console.error('Request cancelled:', error.message)
      return Promise.reject(error)
    }
  )
  
  reward_wheel_axios.interceptors.response.use(
    response => {
      removePendingRequest(response.config)
      return response
    },
    error => {
      removePendingRequest(error.config || {})
      return Promise.reject(error)
    }
  )
  
  window.addEventListener('offline', () => {
    console.log('Internet connection lost')
    isInternetConnected = false
    cancelPendingRequests()
  })
  
  window.addEventListener('online', () => {
    isInternetConnected = true
  })
  
  function addPendingRequest(config) {
    if (isInternetConnected) {
      config.cancelToken = new axios.CancelToken(cancel => {
        pendingRequests.set(getRequestKey(config), cancel)
      })
    }
  }
  
  function removePendingRequest(config) {
    pendingRequests.delete(getRequestKey(config))
  }
  
  function getRequestKey(config) {
    return config.url + JSON.stringify(config.params)
  }
  
  function cancelPendingRequests() {
    for (const [key, cancel] of pendingRequests) {
      cancel('Cancelled due to lost internet connection')
    }
    pendingRequests.clear()
  }

export {
    reward_wheel_axios
}


const router = new VueRouter({ mode: 'history', routes: routes });
Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY hh:mm')
    }
});

// axios interceptor code for error handling
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const originalRequest = error.config;
    if (error.response.data.code == 401 && error.response.data.data != null && !originalRequest._retry) {
        originalRequest._retry = true;
        axios.defaults.headers.common["Authorization"] = error.response.data.data;
        originalRequest.headers["Authorization"] = error.response.data.data;
        localStorage.setItem("consumer_token", error.response.data.data);
        return axios(originalRequest);
    } else if (error.response.data.code == 401) { // Written for JWT algorith change. If a consumer logged in with HS256 algo and did not logged out. After deploying the RS256 algo of JWT the consumer will face a Inavlid JWT token issue. Hence logging out the consumer.
        localStorage.removeItem("consumer_token");
        localStorage.removeItem("nopurchasepower");
        localStorage.removeItem("purchasepower");
        localStorage.removeItem("consumer_login_response");
        localStorage.removeItem("enrollment");
        delete axios.defaults.headers.common["Authorization"];
        router.push("/login").catch((err) => {});
    }
    return Promise.reject(error);
});

reward_wheel_axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const originalRequest = error.config;
    if (error.response.data.code == 401 && error.response.data.data != null && !originalRequest._retry) {
        originalRequest._retry = true;
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + error.response.data.data;
        originalRequest.headers["Authorization"] = 'Bearer ' + error.response.data.data;
        localStorage.setItem("consumer_token", error.response.data.data);
        return reward_wheel_axios(originalRequest);
    }
    return Promise.reject(error);
});

Vue.config.productionTip = false;

// Begin Code Snippet for Coview
(function(c, o, v, i, e, w) {
    c[v] = c[v] || function() {
        (c[v].a = c[v].a || []).push(arguments)
    };
    var s = o.createElement(i);
    s.src = e;
    s.async = 1;
    var h = o.getElementsByTagName(i)[0];
    h.parentNode.insertBefore(s, h);
    c.addEventListener('error', function(err) {
        c[v]('error', err)
    });
    c.addEventListener('message', function(msg) {
        c[v]('message', msg)
    })
})(window, document, 'coview', 'script', 'https://cdn.coview.com/coview.js');
coview('start', {
    projectKey: 'hw2EYN_6aqI'
});
// End Code Snippet for Coview 

// Number format global method 
Vue.mixin({
    methods: {
        pointNumberFormatter(val) {
            if(val){
                let nf = new Intl.NumberFormat('en-US');
                return nf.format(val)
            }else{
                return 0
            }
        },
        amountDecimalNumberFormatter(val) {
            if (val) {
                return new Intl.NumberFormat('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(val);
            } else {
                return '0.00';
            }
        },
        amountNumberFormatter(val) {
          if (val) {
            var formattedNumber = val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            return formattedNumber.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          } else {
            return '0.00';
          }
      },
    }
});

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');