<template>
    <div class="position-relative">

        <div class="invitation-layer">
            <svg width="70" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 570.4 570.4" style="enable-background:new 0 0 570.4 570.4;" xml:space="preserve">
            <g>
            <circle class="st0" cx="285.2" cy="285.2" r="285.2"/>
            <g>
            <path d="M285.4,286c-12.9,12.8-25.7,25.6-38.6,38.4c-45.7,45.7-91.5,91.4-137.2,137.2c-1.5,1.5-3.1,2.9-5.3,4.9
            c-6.9-8-13.9-15.4-20.1-23.4c-31.9-40.7-49.9-86.8-54-138.4c-0.2-1.6-0.5-3.2-0.9-4.8v-15c85.4,0,170.7,0,256.1-0.1L285.4,286z"/>
            <path d="M541.4,284.9c-85,0-170.1,0-255.1-0.1c-0.3,0-0.7,0.1-1,0.1c1.3-1.5,2.5-3.1,3.9-4.5c57.5-57.5,115-115,172.5-172.5
            c1.3-1.3,2.6-2.5,4.6-4.3c5.8,6.6,11.7,12.6,17,19.2c31.7,39,50.6,83.4,56.2,133.4c0.6,5.3,1.3,10.5,1.9,15.7L541.4,284.9z"/>
            <path class="st1" d="M466.1,103.3C406,163.4,345.8,223.5,285.7,283.6c-0.2,0.2-0.4,0.5-0.6,0.8c-0.2-2-0.5-4-0.5-6
            c0-81.3,0-162.6,0-243.9c0-1.8,0.1-3.6,0.2-6.3c8.8,0.5,17.2,0.7,25.6,1.5c50,5.1,94.7,23.2,134,54.6c4.1,3.3,8.3,6.5,12.5,9.8
            L466.1,103.3z"/>
            <path class="st2" d="M103.8,103.3c60.1,60.1,120.2,120.3,180.3,180.4c0.2,0.2,0.5,0.4,0.8,0.6c-2,0.2-4,0.5-6,0.5
            c-81.3,0-162.6,0-243.9,0c-1.8,0-3.6-0.1-6.3-0.2c0.5-8.8,0.7-17.2,1.5-25.6c5.1-50,23.2-94.7,54.6-134c3.3-4.1,6.5-8.3,9.8-12.5
            L103.8,103.3z"/>
            <path class="st1" d="M104.3,467c60.1-60.1,120.3-120.2,180.4-180.3c0.2-0.2,0.4-0.5,0.6-0.8c0.2,2,0.5,4,0.5,6
            c0,81.3,0,162.6,0,243.9c0,1.8-0.1,3.6-0.2,6.3c-8.8-0.5-17.2-0.7-25.6-1.5c-50-5.1-94.7-23.2-134-54.6c-4.1-3.3-8.3-6.5-12.5-9.8
            L104.3,467z"/>
            <path class="st2" d="M466.5,466.3C406.4,406.2,346.3,346,286.3,285.8c-0.2-0.2-0.5-0.4-0.8-0.6c2-0.2,4-0.5,6-0.5
            c81.3,0,162.6,0,243.9,0.1c1.8,0,3.6,0.1,6.3,0.2c-0.5,8.8-0.7,17.2-1.5,25.6c-5.1,50-23.2,94.7-54.6,134
            c-3.3,4.1-6.5,8.3-9.8,12.5L466.5,466.3z"/>
            <path class="st3" d="M284.5,28.3c0,85,0,170.1-0.1,255.1c0,0.3,0.1,0.7,0.1,1c-1.5-1.3-3.1-2.5-4.5-3.9
            C222.5,223,165,165.5,107.5,108c-1.3-1.3-2.5-2.6-4.3-4.6c6.6-5.8,12.6-11.7,19.2-17c39-31.7,83.4-50.6,133.4-56.2
            c5.3-0.6,10.5-1.3,15.7-1.9H284.5z"/>
            <path class="st4" d="M285.4,540.9c-0.1-1.7-0.2-3.3-0.2-5c0-81.9,0-163.8,0-245.6c0-1.5,0.1-3,0.2-4.5c1.1,0.9,2.4,1.8,3.4,2.8
            c58,57.9,115.9,115.9,173.8,173.8c0.9,0.9,1.8,1.9,3,3.3c-1,1.1-1.9,2.3-2.9,3.3c-37.9,36.2-82.5,59-134.2,67.7
            c-11,1.8-22.1,2.8-33.1,4.2L285.4,540.9z"/>
            </g>
            </g>
            </svg>

        </div>

        <div class="invitation-content">
            <p class="invitation-text">CanPay is getting Even More Rewarding! Welcome to the new CanPay Points Rewards program.</p>
            <p class="invitation-text">
                I have read and agreed to CanPay’s <a
                    href="https://www.canpaydebit.com/about-us/terms-conditions/"
                    target="_blank"
                    >Terms and Conditions</a
                    > and <a
                    href="https://www.canpaydebit.com/about-us/privacy/"
                    target="_blank"
                    >Privacy Policy</a
                    > to enter into the CanPay Rewards Points Program.
            </p>
            <slot name="closeModal"></slot>
            <div class="row justify-content-center">
                <div class="col-6 col-md-4">
                    <button
                    type="button"
                    @click="cancelInvitation()"
                    class="invite-btn cancel w-100 h-100"
                    style="height: 100%!important;"
                    >
                    DECLINE
                    </button>
                </div>

                <div class="col-6 col-md-4">
                    <button
                    type="button"
                    @click="acceptInvitation()"
                    class="invite-btn accept w-100 h-100 d-flex align-items-center justify-content-center"
                    style="height: 100%!important;"
                    >
                    ACCEPT
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "../api/rewardwheel.js";

export default {
    name: 'InvitationComponent',
    props:{
        modal: null
    },
    data(){
        return{

        }
    },
    methods: {
        acceptInvitation(){

            api
            .acceptDeclineInvitation({status: 'accepted'})
            .then((response1) => {
                this.modal.hide()
            })
            .catch(function (error) {
            });


            setTimeout(() => {
                api
                    .getSpinData()
                    .then((response2) => {

                    var consumer_login_response = JSON.parse(localStorage.getItem("consumer_login_response"));

                    consumer_login_response.rw_invited = 0
                    consumer_login_response.rw_state = 'allowed'

                    localStorage.setItem(
                    "consumer_login_response",
                    JSON.stringify(consumer_login_response)
                    );

                    const rwStateCahngedEvent = new CustomEvent('rwStateCahnged', {detail: consumer_login_response});
                    document.dispatchEvent(rwStateCahngedEvent);

                    const rwSpinCountEventEvent = new CustomEvent('rwSpinCountEvent', {detail: response2.data});
                    document.dispatchEvent(rwSpinCountEventEvent);

                    this.$router
                    .push({ path: '/rewardWheel' })
                })
                .catch(function (error) {
                });
            }, 1000);
        },
        cancelInvitation(){
            api
            .acceptDeclineInvitation({status: 'declined'})
            .then((response) => {

                var consumer_login_response = JSON.parse(localStorage.getItem("consumer_login_response"));

                consumer_login_response.rw_invited = 0
                consumer_login_response.rw_state = 'not_allowed'

                localStorage.setItem(
                  "consumer_login_response",
                  JSON.stringify(consumer_login_response)
                );

                const event = new CustomEvent('rwStateCahnged', {detail: consumer_login_response});
                document.dispatchEvent(event);

                const ignoreEvent = new CustomEvent('ignoreInvitationCahnged', {detail: {ignoreInvitation: true}});
                document.dispatchEvent(ignoreEvent);

                this.modal.hide()
            })
            .catch(function (error) {
            });
        },
        hideModal(){
            this.modal.hide()
        }
    }
}
</script>

<style>
.invite-btn{
    border: 0;
    padding: 17px 30px;
    font-weight: 500;
    border-radius: 30px;
    font-size: 16px;
}
.invite-btn.accept{
    background-color: #149240;
    color: #ffffff;
}
.invite-btn.cancel{
    background-color: black;
    color: #ffffff;
}
.close-modal{
    position: absolute;
    top: 0;
    right: 0;
}

.invitation-layer{
    position: absolute;
    top: -25%;
    background: #ffff;
    border-radius: 100%;
    padding: 13px;
    left: 50%;
    transform: translateX(-50%);
}
.invitation-content{
    padding-top: 28px;
}
.invitation-text{
    text-align: justify;
}
</style>