<template> 
  <div>
    <div>
    <b-modal
      ref="no-primary-account-modal"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      modal-backdrop
      hide-header
      id="no-primary-account-modal"
      centered
    >
      <div class="color">
        <div class="col-12 text-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="120"
            height="120"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            fill="#e14343"
          >
            <path
              d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
            />
          </svg>
        </div>
        <div class="purchaserpower-modal-text" v-if="modal_type == 'no-primary-account'">
          <div class="d-block text-center" v-if="!pervious_finicity_bank">
            <label class="purchasepower-def-label">{{consumer_local_storage_details.force_delink == 1 ? error_message_force_delink : error_message_generic }}</label>
            <br />
          </div>
          <div class="d-block text-center" v-else>
            <p class="npa-modal-bank-name">{{pervious_finicity_bank.bank_name}}</p>
            <p class="npa-modal-account-no">x{{pervious_finicity_bank.account_no}}</p>
            <label class="purchasepower-def-label">{{error_message_pervious_finicity_bank}}</label>
          </div>
          <div class="row" v-if="!pervious_finicity_bank">
            <div class="col-12 text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="changeBankAccount()"
              >
                <span class="purchasepower-modal-ok-label"
                  >{{consumer_local_storage_details.force_delink == 1 ? 'Relink Banking' : 'Add Primary Account'}}</span
                >
              </button>
            </div>
          </div>
          
          <div class="row mt-3" v-else>
            <div class="col-12">
              <button
                type="button"
                class="btn-login"
                @click="moveToOtherBankingSolution()"
              >
              Relink Banking
              </button>
            </div>
            <div class="col-12 text-center mt-3 npa-modal-chnage-bank">
              <span class="bl-footer-text">
                Want to change your banking details?<br />
                <span class="bl-f-color" @click="changeBankAccount()"
                  ><u>Change banking.</u>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="purchaserpower-modal-text" v-else-if="modal_type == 'finicity-special-message'">
          <div class="d-block text-center">
            <label class="purchasepower-def-label finicity-special-message-label">{{finicity_special_message}}</label>
            <label class="purchasepower-def-label finicity-special-message-label">{{finicity_special_second_message}}</label>
            <br />
          </div>
          
          <div class="row mt-3">
            <div class="col-12" v-if="other_banking_solution_enable">
              <button
                type="button"
                class="btn-login"
                @click="moveToOtherBankingSolution()"
              >
              Relink Banking
              </button>
            </div>
            <div class="col-12" v-else>
              <button
                type="button"
                class="btn-login"
                @click="changeBankAccount()"
              >
              Change Bank
              </button>
            </div>
            <div class="col-12 text-center mt-3 npa-modal-chnage-bank" v-if="!other_banking_solution_enable">
              <span class="bl-footer-text">
                <span class="bl-f-color cursor-pointer" @click="manualLinkWithSameAccount()">
                  <u>Manual link with same account</u>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    
    <b-modal
        ref="bank-change-error-message-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        no-close-on-backdrop
        hide-header
        id="bank-change-error-message-modal"
        centered
      >
      <div class="color">
        <div class="col-12 text-center" v-if="is_api_error">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="120"
            height="120"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            fill="#e14343"
          >
            <path
              d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
  M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
            />
          </svg>
        </div>
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              {{error_message_bank_change}}
            </label>
          </div>
          <div class="row text-center mt-3">
            <div class="col-12">
              <button
                type="button"
                class="btn-black btn-verify"
                @click="hideErrorMessageModal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <real-account-number-modal page_name="return-list" ref="RealAccountNumberModal"></real-account-number-modal>
  </div>
</div>
    
</template>
<script>
import api from "../../api/account.js";
import constants from "../Common/constant.js";
import Loading from "vue-loading-overlay";
import RealAccountNumberModal from './RealAccountNumberModal.vue';

  export default {
    name: "NoPrimaryAccountModal",
    components: {
      Loading,
      RealAccountNumberModal
    },
    data() {
        return {
          error_message_generic: 'The connection to your bank/credit union has disconnected. Please relink your banking to continue.',
          error_message_force_delink: 'CanPay has encountered an error while connecting to your financial institution. Please relink your banking.',
          error_message_pervious_finicity_bank: 'CanPay has encountered an error while connecting to your financial institution. Please relink your banking.',
          consumer_local_storage_details: JSON.parse(
            localStorage.getItem("consumer_login_response")
          ),
          pervious_finicity_bank: null,
          modal_type: 'no-primary-account',
          finicity_special_message: '',
          finicity_special_second_message: '',
          error_message_bank_change: '',
          other_banking_solution_enable: false,
          is_api_error: false,
        };
    },
    methods: {
    generateConnectFix(id) {
      let self = this;
      this.isLoading = true;
      var request = {
        institution_id: id,
      };
      api
        .generateConnectFix(request)
        .then((response) => {
          if (response.code == 200) {
            const finicityConnectUrl = response.data.link;
            window.finicityConnect.launch(finicityConnectUrl, {
              selector: "#connect-container",
              overlay: "rgba(255,255,255, 0)",
              success: function (data) {
                console.log("Yay! We got data", data);
                if (data.code == 200) {
                  //now store the details at canpay end
                  self.storeBankDetails();
                }
              },
              cancel: function () {
                self.isLoading = false;
                console.log("The user cancelled the iframe");
              },
              error: function (err) {
                console.log(err);
                self.isLoading = false;
                console.error(
                  "Some runtime error was generated during Finicity Connect",
                  err
                );
              },
              loaded: function () {
                self.isLoading = false;
                console.log(
                  "This gets called only once after the iframe has finished loading"
                );
              },
              route: function (event) {
                self.isLoading = false;
                console.log(
                  "This is called as the user progresses through Connect"
                );
              },
              user: function (event) {
                if (event.data.errorCode) {
                  console.log(event.data.data.institutionId);
                  setTimeout(() => {
                    window.finicityConnect.destroy();
                    //if error code is present then call the connect fix api
                    self.generateConnectFix(event.data.data.institutionId);
                  }, 2000);
                }
              },
            });
          } else {
            self.isLoading = false;
          }
        })
        .catch((err) => {
          self.isLoading = false;
          console.log(err);
        });
    },
    //calls the api to change bank account through finicity portal
    changeBankAccount() {
      let self = this;
      self.$bvModal.hide("no-primary-account-modal");
      self.$router.push("/banklinking");
    },
    clickUpdateBanking() {
      var self = this;
      self.isLoading = true;
      self.$bvModal.hide("no-primary-account-modal");
      self.$bvModal.hide("update-bank-modal");
      self.consumer_local_storage_details = JSON.parse(
            localStorage.getItem("consumer_login_response")
          );
      if(self.consumer_local_storage_details.bank_link_type == 1){
        api
        .updateLinkGenerate()
        .then((response) => {
          if (response.code == 200) {
            const finicityConnectUrl = response.data.link;
            window.finicityConnect.launch(finicityConnectUrl, {
              selector: "#connect-container",
              overlay: "rgba(255,255,255, 0)",
              success: function (data) {
                console.log("Yay! We got data", data);
                if (data.code == 200) {
                  //now store the details at canpay end
                  self.storeBankDetails();
                }
              },
              cancel: function () {
                self.isLoading = false;
                console.log("The user cancelled the iframe");
              },
              error: function (err) {
                self.isLoading = false;
                console.error(
                  "Some runtime error was generated during Finicity Connect",
                  err
                );
              },
              loaded: function () {
                self.isLoading = false;
                console.log(
                  "This gets called only once after the iframe has finished loading"
                );
              },
              route: function (event) {
                self.isLoading = false;
              },
              user: function (event) {
                if (event.data.errorCode) {
                  console.log(event.data.data.institutionId);
                  setTimeout(() => {
                    window.finicityConnect.destroy();
                    //if error code is present then call the connect fix api
                    self.generateConnectFix(event.data.data.institutionId);
                  }, 2000);
                }
              },
            });
          } else {
            self.isLoading = false;
          }
        })
        .catch((err) => {
          self.isLoading = false;
          console.log(err);
        });
      }else{
        self.$router.push("/banklinking");
      }
    },
    //stores the bank details into canpay end
    storeBankDetails(is_exit=0) {
      var self = this;
      this.isLoading = true;
      api
        .updateBank()
        .then((response) => {
          if (response.code == 200) {
            localStorage.setItem(
              "consumer_login_response",
              JSON.stringify(response.data)
            );
            self.parentPageload();
          }
        })
        .catch(function (error) {
          self.isLoading = false;
          console.log(error);
          if (error.response.data.code == 598) {
            self.$bvModal.show("no-primary-account-modal");
          } else if(error.response.data.code == 597 && is_exit == 0){
            self.generateConnectFix(error.response.data.data.institutionId);
          }else {
            self.showValidationModal(error.response.data.message);
          }
        });
    },
    parentPageload(){
      var self = this;
      if(self.$route.name == 'pay'){
        self.$parent.getModifyData();
        self.$parent.last_four_account_no = response.data['account_no'];
      }else if(self.$route.name == 'BankList'){
        self.$parent.getBankAccountList();
        setTimeout(() => {
          self.$parent.$refs.RealAccountNumberModal.checkForRealAccountNumber();
        }, 2000);
      }else if(self.$route.name == 'profile'){
        self.$parent.getUserDetails();
        setTimeout(() => {
          self.$parent.$refs.RealAccountNumberModal.checkForRealAccountNumber();
        }, 2000);
      }else{
        self.$refs.RealAccountNumberModal.checkForRealAccountNumber();
      }
    },
    getUserdetails(show_modal){
			var self = this;
			api
			.getUserdetails()
			.then(function (response) {
				if (response.code == 200) {
				localStorage.setItem(
					"consumer_login_response",
					JSON.stringify(response.data)
				);
				self.consumer_local_storage_details = localStorage.getItem("consumer_login_response")
				? JSON.parse(localStorage.getItem("consumer_login_response"))
				: null;
				if (show_modal) {
            self.$bvModal.show("no-primary-account-modal");
          } else {
            if (self.$route.name == 'pay') {
              self.$parent.currentUser = self.consumer_local_storage_details;
              self.$parent.last_four_account_no = self.consumer_local_storage_details.account_no;
              if (self.consumer_local_storage_details.bank_link_type == 0) {
                self.$parent.manual_bank_link = true;
              }
              self.$parent.getPurchasePower();
            }
          }
				}
			})
			.catch(function (error) {
			});
		},

    getExistingFinicityBankingSolutionData(){
			var self = this;
      localStorage.removeItem('migration_from_finicity_to_other');
			api
			.getExistingFinicityBankingSolutionData()
			.then(function (response) {
        if (response.data) {
          self.pervious_finicity_bank = response.data;
          localStorage.setItem("bank_search_txt", self.pervious_finicity_bank.routing_no);
        }
        self.$bvModal.show("no-primary-account-modal");
			})
			.catch(function (error) {
        self.$bvModal.show("no-primary-account-modal");
			});
		},
    moveToOtherBankingSolution() {
      let self = this;
      self.$bvModal.hide("no-primary-account-modal");
      localStorage.setItem("migration_from_finicity_to_other",true);
      self.$router.push("/banklinking");
    },
    showModal() {
      let self = this;
      if (self.$route.name != 'BankLinkingOption') {
        self.getExistingFinicityBankingSolutionData();
      }
    },
    showFinicitySpecialMessageModal() {
      let self = this;
      localStorage.removeItem('migration_from_finicity_to_other');
      localStorage.setItem("bank_search_txt", self.consumer_local_storage_details.active_routing_no);
      self.other_banking_solution_enable = self.consumer_local_storage_details.other_banking_solution_enable;
      self.finicity_special_message = "CanPay has upgraded its connection to your bank (enhancements to security and reliability). You must relink your banking to complete the upgrade and continue using CanPay.";
      self.finicity_special_second_message = "Failure to relink will cause loss of Purchase Power and can impact your ability to complete purchases with CanPay until you do relink.";
      self.modal_type = 'finicity-special-message';
      self.$bvModal.show("no-primary-account-modal");
    },
    hideModal() {
      let self = this;
      localStorage.removeItem('migration_from_finicity_to_other');
      self.$bvModal.hide("no-primary-account-modal");
    },
    skipBankReLink() {
      let self = this;
      localStorage.removeItem('migration_from_finicity_to_other');
      self.$bvModal.hide("no-primary-account-modal");
      api
			.skipBankReLink()
			.then(function (response) {
        self.consumer_local_storage_details.bank_relink_skipped_at = response.data;
        localStorage.setItem("consumer_login_response", JSON.stringify(self.consumer_local_storage_details));
			})
			.catch(function (error) {
			});
    },
    manualLinkWithSameAccount() {
      let self = this;
      self.is_api_error = false;
      api
			.manualLinkWithSameAccount()
			.then(function (response) {
        self.getUserdetails(false);
        self.$bvModal.hide("no-primary-account-modal");
			})
			.catch(function (error) {
        console.log(error.response);
        self.$bvModal.hide("no-primary-account-modal");
        self.is_api_error = true;
        self.$bvModal.show("bank-change-error-message-modal");
        self.error_message_bank_change = error.response.data.message;
			});
    },
    hideErrorMessageModal() {
      let self = this;
      self.$bvModal.hide("bank-change-error-message-modal");
    },

    }, 
    props: [      
    ],
    mounted: function () {
      
    },
    created() {
    }
  };
</script>
<style lang="scss">
.b-button {
  background-color: transparent;
}
#no-primary-account-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#error-message-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#bank-change-error-message-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}
.npa-modal-bank-name {
  font-size: 33px;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: Circular-Loom;
}
.npa-modal-account-no {
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #007EE5;
  font-family: 'Open Sans';
}
.npa-modal-chnage-bank {
  font-weight: 600;
  font-size: 14px !important;
}
.finicity-special-message-label {
  font-size: 17px !important;
}
</style>