<template>
  <div class="pre-header">
    <div class="row pre-header-height">
      <!--Code for ToggleDrawer-->
      <!--Code for CanPay Logo-->
      <div class="col-12 align-self-center">
        <img
          class="pre-header-logo align-self-center"
          src="../../../assets/images/canpay-logo.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreHeader",
  props: {
    msg: String,
  },
};
</script>
