const loginUser = (request) => {
    return new Promise((res, rej) => {
        axios.post('login', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const logout = (request) => {
    return new Promise((res, rej) => {
        axios.get('logout', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getConsumerStatus = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.post('getconsumerstatus', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const consumerAccountVerifyCode = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.post('consumeraccountverifycode', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const consumerAccountVerifyCodeResend = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.post('consumersendverificationcode', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const checkStateAge = (request) => {
    return new Promise((res, rej) => {
        axios.post('checkstateage', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};


export default {
    loginUser,
    logout,
    getConsumerStatus,
    consumerAccountVerifyCodeResend,
    consumerAccountVerifyCode,
    checkStateAge

};