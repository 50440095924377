<template>
    <div class="background-bg">
        <div>
            <img src="../../assets/gif/loader.gif" alt="CanPayLoader" class="loader-size">
        </div>
    </div>
</template>
<script>
export default {
    name: "CanPayLoader"
}
</script>
<style scoped>
.background-bg{
    width:100vw;
    height:100vh;
    background-color:rgba(50, 50, 50, 0.746);
    position:absolute;
    top:0px;
    display:flex;
    z-index:9999999999999;
    justify-content: center;
    align-items:center;
}
.loader-size{
    width:5rem;
    height:5rem;
}
</style>
