<template> 
<div>
    <div>
    <b-modal
        ref="real-account-no-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        id="real-account-no-modal"
        centered
      >
      <div class="row" v-if="is_loading">
        <loading
          :active.sync="is_loading"
          :can-cancel="true"
        ></loading>
      </div>
        <div class="color">
          <div class="col-12 text-center">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="120"
              height="120"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125"
              xml:space="preserve"
              fill="#e14343"
            >
              <path
                d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
              />
            </svg>
          </div>
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                Important: Your bank provided an incomplete information.
                Please <span v-if="selection_available == 1">select from existing or</span> enter your bank routing number and account number below.
              </label>
            </div>
            <br />
            <div class="row" v-if="selection_available == 1">
              <div class="col-12">
                <div class="row bank-name-list m-0">
                  <div class="col-2 align-self-center">
                  <div class="text-center">
                    <input
                      :id="previous_selection_data.id"
                      class="radio-custom"
                      name="radio-group"
                      type="checkbox"
                      @click="selectAccount(previous_selection_data)"
                    />
                    <label
                      :for="previous_selection_data.id"
                      class="radio-custom-label float-left"
                    ></label>
                  </div>
                  </div>
                  <div class="col-4 align-self-center bl-f-weight">
                    <span class="ml-2">x{{ previous_selection_data.account_no }}</span>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div v-if="selection_available == 1" class="separator">OR</div>
            <br />
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    autocomplete="nope"
                    style="padding-left: 1rem !important"
                    v-model="real_routing_no"
                    inputmode="numeric"
                    placeholder="Routing Number (Numbers Only)"
                    type="text"
                    @keypress="isNumber($event)"
                    maxlength="9"
                    minlength="9"
                    :disabled="isDisabled"
                  />
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    autocomplete="nope"
                    style="padding-left: 1rem !important"
                    v-model="real_account_no"
                    inputmode="numeric"
                    placeholder="Account Number (0-9 , A-Z only)"
                    type="text"
                    @keypress="isAlphanumericWithoutSpace($event)"
                    maxlength="17"
                    minlength="5"
                    :disabled="isDisabled"
                  />
                </div>
              </div>
            </div>
            <div class="row text-center mt-3">
              <div class="col-12">
                <button
                  type="button"
                  class="btn-login btn-get-started"
                  @click="updateRealAccountNumber()"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref="error-message-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        no-close-on-backdrop
        hide-header
        id="error-message-modal"
        centered
      >
        <div class="color">
          <div class="col-12 text-center">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="120"
              height="120"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125"
              xml:space="preserve"
              fill="#e14343"
            >
              <path
                d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
              />
            </svg>
          </div>
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{error_message}}
              </label>
            </div>
            <div class="row text-center mt-3">
              <div class="col-12">
                <button
                  type="button"
                  class="btn-black btn-verify"
                  @click="hideModal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <incomplete-account-number-modal :page_name="page_name" ref="IncompleteAccountNumberModal"></incomplete-account-number-modal>
      </div>
</div>
    
</template>
<script>
import api from "../../api/account.js";
import Loading from "vue-loading-overlay";
import IncompleteAccountNumberModal from './IncompleteAccountNumberModal.vue';
  export default {
    name: "RealAccountNumberModal",
    components: {
      Loading,
      IncompleteAccountNumberModal
    },
    data() {
        return {
            currentUser: localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null,
            is_loading:false,
            real_routing_no: "",
            real_account_no: "",
            error_message: "",
            selection_available: 0,
            previous_selection_data: null,
            previous_selection_data_id: null,
            selected_account:"",
            isDisabled: false,
            balance_checking: localStorage.getItem("balance_checking"),
            savebank:""
        };
    },
    props: ['page_name'],
    methods: {
        isNumber: function (evt) {
            const regex = /[0-9]/g;
            evt = evt ? evt : window.event;
            var inputKey = String(evt.key);
            if (!inputKey.match(regex)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        isAlphanumericWithoutSpace: function (evt) {
            const regex = /^[A-Za-z0-9]+$/;
            evt = evt ? evt : window.event;
            var inputKey = String(evt.key);
            if (!inputKey.match(regex)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        selectAccount(account) {
          let self = this;
          self.previous_selection_data_id = account.id;
          if($('#' + account.id).is(":checked")){
            self.isDisabled = true;
            self.real_account_no = null;
            self.real_routing_no = null;
          }else{
            self.isDisabled = false;
            self.previous_selection_data_id = null;
          }
          
        },
        checkForRealAccountNumber(savebank=false,bank_id=false){
            let self = this;
            self.currentUser = localStorage.getItem("consumer_login_response")
            ? JSON.parse(localStorage.getItem("consumer_login_response"))
            : null;
            self.selected_account = bank_id;
            self.savebank = savebank;
            var request = {
                account_id: bank_id,
                check_tan:savebank == 1 && self.page_name === 'banklist' ? 1 : 0
            };
            api
                .checkForRealAccountNumber(request)
                .then((response) => {
                if (response.code == 200) {
                    if(response.data.account_details_required == 1){
                        self.$bvModal.show("real-account-no-modal");
                    } else {
                      if (self.currentUser.routing_no) {
                        setTimeout(() => {
                          this.$refs.IncompleteAccountNumberModal.showAccountNoModal();
                        }, 500);
                      }else{
                        self.afterRealAccountNumberCheck(false);
                      }
                    }
                    if(response.data.last_real_account_details){
                      self.selection_available = 1;
                      self.previous_selection_data = response.data.last_real_account_details;
                    }
                }
                })
                .catch((err) => {
                  console.log(err);
                });
        },
        afterRealAccountNumberCheck(fromIncompleteModal=false) {
          var self = this;
          if(self.page_name === 'return_banklist' && (self.savebank == 1 || fromIncompleteModal)){
            if(self.balance_checking == 1){
              self.$parent.getBankAccountList();
            }else{
              self.$parent.getManualBankAccountList();
            }
            if (self.savebank == 1) {
              self.$parent.returnRepresentTransaction();
            }
          }else if(self.page_name === 'banklist' && (self.savebank == 1 || fromIncompleteModal)){
              if (self.savebank == 1) {
                self.$parent.save();
              }
              // self.$parent.getBankAccountList();
          }
        },
        updateRealAccountNumber() {
            var self = this;
            if(!self.previous_selection_data_id){
               if (self.real_account_no === "" || self.real_account_no.trim().length < 5) {
                    alert("Please enter a valid bank account number.");
                    return false;
                }
                if (self.real_routing_no === "" || self.real_routing_no.trim().length != 9) {
                    alert("Please enter a valid bank routing number.");
                    return false;
                }
                if(self.real_routing_no == self.real_account_no){
                    alert("Account number and routing number can't be same. Please provide valid banking details.");
                    return false;
                }
                if(this.checkAccountNumberContainNumber(self.real_account_no)){
                  alert("Account Number must have minimum 4 numbers.");
                  return false;
                }
                if (self.real_routing_no.length != 9) {
                  alert("Invalid routing number (must be 9 digits)");
                  return false;
                }
            }
            self.is_loading = true;
            var request = {
                account_no: self.real_account_no,
                routing_no: self.real_routing_no,
                previous_selection_data_id: self.previous_selection_data_id,
                selected_account:self.selected_account
            };
            api
                .updateRealAccountNumber(request)
                .then((response) => {
                if (response.code == 200) {
                    self.real_account_no = "";
                    self.real_routing_no = "";
                    self.$bvModal.hide("real-account-no-modal");
                    self.is_loading = false;
                    self.getUserDetails();
                    if(self.page_name === 'pay' || self.page_name == 'return-list'){
                        self.$parent.getReturnTransactions();
                    }else if(self.page_name === 'banklist'){
                        if(self.savebank == 1){
                          self.$parent.save();
                        }
                        self.$parent.getBankAccountList();
                    }else if(self.page_name === 'profile'){
                        self.$parent.getUserDetails();
                    }else if(self.page_name === 'return_banklist'){
                        self.$parent.getBankAccountList();
                        self.$parent.returnRepresentTransaction();
                    }
                }
                }).catch(function (err) {
                    self.is_loading = false;
                    self.error_message = err.response.data.message;
                    self.$bvModal.show("error-message-modal");
                });
        },

        hideModal(){
            this.$bvModal.hide("error-message-modal");
        },

        checkAccountNumberContainNumber(accountNumber) {
          let checkAccountNumber;
          checkAccountNumber = accountNumber.split("");
          let count;
          count = 0;
          checkAccountNumber.forEach(checkValue);

          function checkValue(value, index, array) {
            if (!isNaN(value)) {
              count++;
            }
          }
          var isFourDigit = count >= 4 ? false : true;
          return isFourDigit;
        },

        getUserDetails() {
          var self = this;
          self.isLoading = true;
          api
            .getUserdetails()
            .then(function (response) {
              if (response.code == 200) {
                localStorage.setItem(
                  "consumer_login_response",
                  JSON.stringify(response.data)
                );
              }
            })
            .catch(function (error) {
            });
        },
    },
    mounted() {
      
    }
  };
</script>
<style lang="scss">
.b-button {
  background-color: transparent;
}
#real-account-no-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#error-message-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}
</style>