<template>
  <div class="container h-100">
    <div class="h-100 position-relative">
        <a class="rp-header-link ml-2" href="javascript:void(0)" @click="showDrawer">
          <i
            class="fa fa-2x fa-bars icon rp-menu-icon"
            style="color: #000000; background-color: #ffffff !important"
            aria-hidden="true"
            v-show="showmenuicon"
          ></i>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 2540 3175"
            style="enable-background:new 0 0 2540 3175;"
            xml:space="preserve"
            height="30"
            width="30"
            v-show="!showmenuicon"
          >
            <g>
              <path
                d="M1878,2270c39,39,39,104,0,143c-40,40-104,40-144,0L662,1342c-39-40-39-104,0-144L1734,127c40-40,104-40,144,0
		c39,39,39,104,0,143L878,1270L1878,2270z"
              />
            </g>
          </svg>
        </a>
        <div class="rp-header-card">
            <img class="header-logo" style="margin-bottom: 0;" src="../../../assets/images/canpay-logo-new.png" />
        </div>
        <div class="row header-link-card">
            <div class="col-6">
                <div :class="classPoint"><span><a class="rp-link" @click="routePush('rewardPoints')" href="javascript:void(0)">CanPay Points</a></span></div>
            </div>
            <div class="col-6">
                <div :class="classHistory"><span><a class="rp-link" @click="routePush('rewardHistory')" href="javascript:void(0)">History</a></span></div>
            </div>
        </div>
    </div>
   
  </div>
</template>

<script>
import DrawerLayout from "vue-drawer-layout";

export default {
  name: "Header",
  components: {
    DrawerLayout
  },
  data: () => ({
    open: true,
    showmenuicon: true
  }),
  computed:{
    classPoint(){
      if(this.$route.name == 'RewardPoints'){
        return 'rp-menu-link rp-menu-span'
      }else{
        return 'rp-menu-link'
      }
    },
    classHistory(){
      if(this.$route.name == 'rewardHistory'){
        return 'rp-menu-link rp-menu-span'
      }else{
        return 'rp-menu-link'
      }
    }
  },
  mounted() {
    let self = this;
    self.$root.$on("Menu Drawer Close", function(data) {
      self.showmenuicon = true;
    });

    self.$root.$on("Menu Drawer Open", function(data) {
      setTimeout(function() {
        self.showmenuicon = false;
      }, 30);
    });
  },
  methods: {
    showDrawer() {
      this.showmenuicon = false;
      this.$root.$emit("Menu Drawer", [""]);
    },
    routePush(url){
        this.$router.push("/"+url).catch((err) => {});
    }
  }
};
</script>

<style>
.rp-menu-icon{
    font-size: 25px;
}
.header-link-card{
    position: absolute;
    bottom: 6px;
    width: 100%;
    padding: 0!important;
    margin: 0!important; 
}
.rp-menu-link{
  padding-bottom: 2px;
}
.rp-menu-span span{
    color: black;
    font-weight: bold;
    position: relative;
    padding-bottom: 10px;
}
.rp-menu-span span:after{
    position:absolute; left:0; bottom:0; width:100%; height:4px; border-bottom:4px solid #000; content:""
}
.rp-menu-span span a{
    color: black;
    opacity: 1;
}

.rp-link{
    color: #757575;
    font-weight: bold;
}
.rp-link:hover{
    color: #757575;
    text-decoration: none;
}
.rp-header-link{
  position: absolute;
  left: 0;
  top: 40%;
  transform: translateY(-50%);
  z-index: 999;
}
.rp-header-card{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}


@media only screen and ( min-width:280px) and ( max-width:700px) {
  .rp-menu-icon{
    font-size: 25px;
  }
  .header-link-card{
      position: absolute;
      bottom: 6px;
      width: 100%;
      padding: 0!important;
      margin: 0!important; 
  }
  .rp-menu-link{
    padding-bottom: 2px;
    font-size: 12px;
  }
  .rp-menu-span span{
      color: black;
      font-weight: bold;
      position: relative;
      padding-bottom: 10px;
  }
  .rp-menu-span span:after{
      position:absolute; left:0; bottom:0; width:100%; height:4px; border-bottom:4px solid #000; content:""
  }
  .rp-menu-span span a{
      color: black;
      opacity: 1;
  }

  .rp-link{
      color: #757575;
      font-weight: bold;
  }
  .rp-link:hover{
      color: #757575;
      text-decoration: none;
  }
  .rp-header-link{
    position: absolute;
    left: 0;
    top: 40%;
    transform: translateY(-50%);
    z-index: 999;
  }
  .rp-header-card{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .reward-point-nav{
    height: 130px!important;
  }
}

@media only screen and ( min-width:320px) and ( max-width:700px) {
  .rp-menu-icon{
      font-size: 25px;
  }
  .header-link-card{
      position: absolute;
      bottom: 6px;
      width: 100%;
      padding: 0!important;
      margin: 0!important; 
  }
  .rp-menu-link{
    padding-bottom: 2px;
    font-size: 14px;
  }
  .rp-menu-span span{
      color: black;
      font-weight: bold;
      position: relative;
      padding-bottom: 10px;
  }
  .rp-menu-span span:after{
      position:absolute; left:0; bottom:0; width:100%; height:4px; border-bottom:4px solid #000; content:""
  }
  .rp-menu-span span a{
      color: black;
      opacity: 1;
  }

  .rp-link{
      color: #757575;
      font-weight: bold;
  }
  .rp-link:hover{
      color: #757575;
      text-decoration: none;
  }
  .rp-header-link{
    position: absolute;
    left: 0;
    top: 40%;
    transform: translateY(-50%);
    z-index: 999;
  }
  .rp-header-card{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .reward-point-nav{
    height: 130px!important;
  }
}

@media only screen and ( min-width:376px) and ( max-width:800px) {
  .rp-menu-icon{
    font-size: 25px;
  }
  .header-link-card{
      position: absolute;
      bottom: 6px;
      width: 100%;
      padding: 0!important;
      margin: 0!important; 
  }
  .rp-menu-link{
    padding-bottom: 2px;
    font-size: 16px;
  }
  .rp-menu-span span{
      color: black;
      font-weight: bold;
      position: relative;
      padding-bottom: 10px;
  }
  .rp-menu-span span:after{
      position:absolute; left:0; bottom:0; width:100%; height:4px; border-bottom:4px solid #000; content:""
  }
  .rp-menu-span span a{
      color: black;
      opacity: 1;
  }

  .rp-link{
      color: #757575;
      font-weight: bold;
  }
  .rp-link:hover{
      color: #757575;
      text-decoration: none;
  }
  .rp-header-link{
    position: absolute;
    left: 0;
    top: 40%;
    transform: translateY(-50%);
    z-index: 999;
  }
  .rp-header-card{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .reward-point-nav{
    height: 180px!important;
  }
}
</style>
