const rewardWheel = {
    state: {
      rewardWheelID: null,
    },
    mutations: {
      setRewardWheelID(state, id) {
        state.rewardWheelID = id;
      },
    },
    actions: {
        //
    },
    getters: {
      getRewardWheelID(state) {
        return state.rewardWheelID;
      },
    },
  };
  
  export default rewardWheel;