<template>
  <div class="container">
    <div class="row"></div>
    <div class="row" style="padding-top: 30px;">
      <div class="col-2 content" slot="content" style="z-index: 999999; background-color: #ffffff;">
        <a href="javascript:void(0)" @click="showDrawer">
          <i
            class="fa fa-2x fa-bars icon menuicon-header-style"
            style="color: #000000; background-color: #ffffff !important; margin-top: 0px !important;"
            aria-hidden="true"
            v-show="showmenuicon"
          ></i>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 2540 3175"
            style="enable-background:new 0 0 2540 3175;"
            xml:space="preserve"
            height="30"
            width="30"
            v-show="!showmenuicon"
          >
            <g>
              <path
                d="M1878,2270c39,39,39,104,0,143c-40,40-104,40-144,0L662,1342c-39-40-39-104,0-144L1734,127c40-40,104-40,144,0
		c39,39,39,104,0,143L878,1270L1878,2270z"
              />
            </g>
          </svg>
        </a>
      </div>
      <div class="col-8"></div>
      <div class="col-2" slot="content" style="z-index: 999999;">
        <!-- <a href="javascript:void(0)" @click="clickEdit">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="10 0 100 125"
            style="enable-background:new 0 0 100 125;"
            xml:space="preserve"
            height="30"
            width="40"
          >
            <path
              d="M82.7,21.2l-3.9-3.9c-5.1-5.1-13.3-5.1-18.4,0l-41,41c-0.4,0.4-0.7,0.9-0.8,1.5l-5,23.1c-0.4,1.6,0.7,3.2,2.3,3.6
	c0.4,0.1,0.9,0.1,1.3,0l23.1-5c0.6-0.1,1.1-0.4,1.5-0.8l41-41C87.8,34.6,87.8,26.3,82.7,21.2C82.7,21.2,82.7,21.2,82.7,21.2z
	 M25.8,60.4l33.1-33.1l13.8,13.8L39.6,74.2L25.8,60.4z M23.3,66.4l10.3,10.3l-13.2,2.9L23.3,66.4z M78.4,35.4L77,36.9L63.1,23
	l1.5-1.5c2.7-2.7,7.2-2.7,9.9,0c0,0,0,0,0,0l3.9,3.9C81.2,28.2,81.2,32.6,78.4,35.4C78.5,35.3,78.5,35.4,78.4,35.4L78.4,35.4z"
            />
          </svg>
        </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import DrawerLayout from "vue-drawer-layout";
export default {
  name: "ProfileHeader",
  components: {
    DrawerLayout
  },

  data: () => ({
    open: true,
    showmenuicon: true
  }),
  mounted() {
    let self = this;
    self.$root.$on("Menu Drawer Close", function(data) {
      self.showmenuicon = true;
    });

    self.$root.$on("Menu Drawer Open", function(data) {
      setTimeout(function() {
        self.showmenuicon = false;
      }, 30);
    });
  },

  methods: {
    showDrawer() {
      this.showmenuicon = false;
      this.$root.$emit("Menu Drawer", [""]);
    },
    clickEdit() {
      let self = this;
      this.$router.push("/editprofile");
    }
  }
};
</script>
