<template>
    <div v-if="rwState == 'allowed' || (rwInvited == 1 && rwState == 'not_allowed')">
        <button v-if="show_spin_info" @click="clickRedirectToWheel" class="rw-timmer-chip">
            <svg width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="20 20 530 530" style="enable-background:new 0 0 570.4 570.4;" xml:space="preserve">
          
            <g>
            <path d="M285.4,286c-12.9,12.8-25.7,25.6-38.6,38.4c-45.7,45.7-91.5,91.4-137.2,137.2c-1.5,1.5-3.1,2.9-5.3,4.9
            c-6.9-8-13.9-15.4-20.1-23.4c-31.9-40.7-49.9-86.8-54-138.4c-0.2-1.6-0.5-3.2-0.9-4.8v-15c85.4,0,170.7,0,256.1-0.1L285.4,286z"/>
            <path d="M541.4,284.9c-85,0-170.1,0-255.1-0.1c-0.3,0-0.7,0.1-1,0.1c1.3-1.5,2.5-3.1,3.9-4.5c57.5-57.5,115-115,172.5-172.5
            c1.3-1.3,2.6-2.5,4.6-4.3c5.8,6.6,11.7,12.6,17,19.2c31.7,39,50.6,83.4,56.2,133.4c0.6,5.3,1.3,10.5,1.9,15.7L541.4,284.9z"/>
            <path class="st1" d="M466.1,103.3C406,163.4,345.8,223.5,285.7,283.6c-0.2,0.2-0.4,0.5-0.6,0.8c-0.2-2-0.5-4-0.5-6
            c0-81.3,0-162.6,0-243.9c0-1.8,0.1-3.6,0.2-6.3c8.8,0.5,17.2,0.7,25.6,1.5c50,5.1,94.7,23.2,134,54.6c4.1,3.3,8.3,6.5,12.5,9.8
            L466.1,103.3z"/>
            <path class="st2" d="M103.8,103.3c60.1,60.1,120.2,120.3,180.3,180.4c0.2,0.2,0.5,0.4,0.8,0.6c-2,0.2-4,0.5-6,0.5
            c-81.3,0-162.6,0-243.9,0c-1.8,0-3.6-0.1-6.3-0.2c0.5-8.8,0.7-17.2,1.5-25.6c5.1-50,23.2-94.7,54.6-134c3.3-4.1,6.5-8.3,9.8-12.5
            L103.8,103.3z"/>
            <path class="st1" d="M104.3,467c60.1-60.1,120.3-120.2,180.4-180.3c0.2-0.2,0.4-0.5,0.6-0.8c0.2,2,0.5,4,0.5,6
            c0,81.3,0,162.6,0,243.9c0,1.8-0.1,3.6-0.2,6.3c-8.8-0.5-17.2-0.7-25.6-1.5c-50-5.1-94.7-23.2-134-54.6c-4.1-3.3-8.3-6.5-12.5-9.8
            L104.3,467z"/>
            <path class="st2" d="M466.5,466.3C406.4,406.2,346.3,346,286.3,285.8c-0.2-0.2-0.5-0.4-0.8-0.6c2-0.2,4-0.5,6-0.5
            c81.3,0,162.6,0,243.9,0.1c1.8,0,3.6,0.1,6.3,0.2c-0.5,8.8-0.7,17.2-1.5,25.6c-5.1,50-23.2,94.7-54.6,134
            c-3.3,4.1-6.5,8.3-9.8,12.5L466.5,466.3z"/>
            <path class="st3" d="M284.5,28.3c0,85,0,170.1-0.1,255.1c0,0.3,0.1,0.7,0.1,1c-1.5-1.3-3.1-2.5-4.5-3.9
            C222.5,223,165,165.5,107.5,108c-1.3-1.3-2.5-2.6-4.3-4.6c6.6-5.8,12.6-11.7,19.2-17c39-31.7,83.4-50.6,133.4-56.2
            c5.3-0.6,10.5-1.3,15.7-1.9H284.5z"/>
            <path class="st4" d="M285.4,540.9c-0.1-1.7-0.2-3.3-0.2-5c0-81.9,0-163.8,0-245.6c0-1.5,0.1-3,0.2-4.5c1.1,0.9,2.4,1.8,3.4,2.8
            c58,57.9,115.9,115.9,173.8,173.8c0.9,0.9,1.8,1.9,3,3.3c-1,1.1-1.9,2.3-2.9,3.3c-37.9,36.2-82.5,59-134.2,67.7
            c-11,1.8-22.1,2.8-33.1,4.2L285.4,540.9z"/>
            </g>
            </svg>
            <span class="d-flex align-items-center ml-1" v-html="nextFreeSpin"></span>
        </button>

        <b-modal
        ref="rw-timer-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="rw-timer-modal"
        class="rw-timer-modal"
        centered
        title="BootstrapVue"
      >
        <div class="rw-timer-modal-body">
            <div class="modal-wheel-layer">
                <svg width="70" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 570.4 570.4" style="enable-background:new 0 0 570.4 570.4;" xml:space="preserve">
                <g>
                <circle class="st0" cx="285.2" cy="285.2" r="285.2"/>
                <g>
                <path d="M285.4,286c-12.9,12.8-25.7,25.6-38.6,38.4c-45.7,45.7-91.5,91.4-137.2,137.2c-1.5,1.5-3.1,2.9-5.3,4.9
                c-6.9-8-13.9-15.4-20.1-23.4c-31.9-40.7-49.9-86.8-54-138.4c-0.2-1.6-0.5-3.2-0.9-4.8v-15c85.4,0,170.7,0,256.1-0.1L285.4,286z"/>
                <path d="M541.4,284.9c-85,0-170.1,0-255.1-0.1c-0.3,0-0.7,0.1-1,0.1c1.3-1.5,2.5-3.1,3.9-4.5c57.5-57.5,115-115,172.5-172.5
                c1.3-1.3,2.6-2.5,4.6-4.3c5.8,6.6,11.7,12.6,17,19.2c31.7,39,50.6,83.4,56.2,133.4c0.6,5.3,1.3,10.5,1.9,15.7L541.4,284.9z"/>
                <path class="st1" d="M466.1,103.3C406,163.4,345.8,223.5,285.7,283.6c-0.2,0.2-0.4,0.5-0.6,0.8c-0.2-2-0.5-4-0.5-6
                c0-81.3,0-162.6,0-243.9c0-1.8,0.1-3.6,0.2-6.3c8.8,0.5,17.2,0.7,25.6,1.5c50,5.1,94.7,23.2,134,54.6c4.1,3.3,8.3,6.5,12.5,9.8
                L466.1,103.3z"/>
                <path class="st2" d="M103.8,103.3c60.1,60.1,120.2,120.3,180.3,180.4c0.2,0.2,0.5,0.4,0.8,0.6c-2,0.2-4,0.5-6,0.5
                c-81.3,0-162.6,0-243.9,0c-1.8,0-3.6-0.1-6.3-0.2c0.5-8.8,0.7-17.2,1.5-25.6c5.1-50,23.2-94.7,54.6-134c3.3-4.1,6.5-8.3,9.8-12.5
                L103.8,103.3z"/>
                <path class="st1" d="M104.3,467c60.1-60.1,120.3-120.2,180.4-180.3c0.2-0.2,0.4-0.5,0.6-0.8c0.2,2,0.5,4,0.5,6
                c0,81.3,0,162.6,0,243.9c0,1.8-0.1,3.6-0.2,6.3c-8.8-0.5-17.2-0.7-25.6-1.5c-50-5.1-94.7-23.2-134-54.6c-4.1-3.3-8.3-6.5-12.5-9.8
                L104.3,467z"/>
                <path class="st2" d="M466.5,466.3C406.4,406.2,346.3,346,286.3,285.8c-0.2-0.2-0.5-0.4-0.8-0.6c2-0.2,4-0.5,6-0.5
                c81.3,0,162.6,0,243.9,0.1c1.8,0,3.6,0.1,6.3,0.2c-0.5,8.8-0.7,17.2-1.5,25.6c-5.1,50-23.2,94.7-54.6,134
                c-3.3,4.1-6.5,8.3-9.8,12.5L466.5,466.3z"/>
                <path class="st3" d="M284.5,28.3c0,85,0,170.1-0.1,255.1c0,0.3,0.1,0.7,0.1,1c-1.5-1.3-3.1-2.5-4.5-3.9
                C222.5,223,165,165.5,107.5,108c-1.3-1.3-2.5-2.6-4.3-4.6c6.6-5.8,12.6-11.7,19.2-17c39-31.7,83.4-50.6,133.4-56.2
                c5.3-0.6,10.5-1.3,15.7-1.9H284.5z"/>
                <path class="st4" d="M285.4,540.9c-0.1-1.7-0.2-3.3-0.2-5c0-81.9,0-163.8,0-245.6c0-1.5,0.1-3,0.2-4.5c1.1,0.9,2.4,1.8,3.4,2.8
                c58,57.9,115.9,115.9,173.8,173.8c0.9,0.9,1.8,1.9,3,3.3c-1,1.1-1.9,2.3-2.9,3.3c-37.9,36.2-82.5,59-134.2,67.7
                c-11,1.8-22.1,2.8-33.1,4.2L285.4,540.9z"/>
                </g>
                </g>
                </svg>

            </div>

            <p class="rw-modal-timer" v-html="nextFreeSpin"></p>
            <p class="rw-modal-text text-center" v-if="datetime">Until Next Free Spin</p>
            <p class="rw-modal-text text-center" v-else>A free spin will be available after your next purchase</p>
            <button
            type="button"
            class="rw-modal-close-btn"
            @click="hideModal('rw-timer-modal')"
            >
            COOL
            </button>
        </div>
        </b-modal>

        <!-- //////////// INVITAION MODAL //////////// -->
        <b-modal
        ref="invitation-modal"
        hide-footer
        v-b-modal.modal-center
        no-close-on-backdrop
        modal-backdrop
        hide-header
        id="invitation-modal"
        centered
        title="BootstrapVue"
        >
        <InvitationComponent :modal="$refs['invitation-modal']">
            <template v-slot:closeModal="">
                <a class="close-modal" @click="hideModal('invitation-modal')" href="javascript:void(0)">
                <svg fill="#000000" width="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.293,18.293,10.586,12,4.293,5.707A1,1,0,0,1,5.707,4.293L12,10.586l6.293-6.293a1,1,0,1,1,1.414,1.414L13.414,12l6.293,6.293a1,1,0,1,1-1.414,1.414L12,13.414,5.707,19.707a1,1,0,0,1-1.414-1.414Z"/></svg>
                </a>
            </template>
        </InvitationComponent>
        </b-modal>
    </div>
</template>

<script>
import api from "../../api/rewardwheel.js";
import moment from 'moment';
import InvitationComponent from '../InvitationComponent.vue';
import $store from '../../store'

export default {
    data() {
        return{
            nextFreeSpin: "",
            nextFreeSpinTime: "",
            spinFlag: false,
            show_spin_info: true,
            rwState: '',
            rwInvited: 0,
            datetime: null,
            lastSpinWheelId: null,
            is_daily_spins_available: false
        }
    },
    components:{
        InvitationComponent
    },
    mounted(){
        var self = this;

        document.addEventListener('rwStateCahnged', (event) => {
            self.rwState = event.detail.rw_state
            self.rwInvited = event.detail.rw_invited
        });

        document.addEventListener('rwSpinCountEvent', (event) => {
            this.datetime = event.detail.next_available_in
            self.setTimmer(event.detail.next_available_in, event.detail.total_spins);
        });

        const consumer_login_response = JSON.parse(localStorage.getItem("consumer_login_response"));
        self.rwState = consumer_login_response.rw_state
        self.rwInvited = consumer_login_response.rw_invited

        self.getSpinData();
        self.getLastSpinWheelId();
        this.$root.$on("showSpinInfo", function (data) {
            self.show_spin_info = data.show_spin_info;
        });
    },
    methods:{
        clickRedirectToWheel(){
            var self = this;
            if(self.$online){
                if(self.rwInvited == 1){
                    self.$refs['invitation-modal'].show();
                }else{
                    if(self.spinFlag){
                        if(self.lastSpinWheelId && self.is_daily_spins_available === false){
                            $store.commit('setRewardWheelID', self.lastSpinWheelId)
                            self.$router.push("/rewardWheel").catch((err) => {});
                        }else{
                            self.$router.push("/rewardWheel").catch((err) => {});
                        }
                    }else{
                        self.showModal("rw-timer-modal");
                    }
                }
            }
        },
        showModal(modal) {
            this.$refs[modal].show();
        },
        hideModal(modal) {
            this.$refs[modal].hide();
        },
        getSpinData(){
            var self = this;
            api
            .getSpinData()
            .then((response) => {
                this.datetime = response.data.next_available_in
                self.setTimmer(response.data.next_available_in, response.data.total_spins);
                self.is_daily_spins_available = parseInt(response.data.daily_spins) > 0 ? true : false;
            })
            .catch(function (error) {
                
            });
        },
        setTimmer(datetime, total_spins){
            var self = this;

            var total_spin_html = '';

            if(total_spins > 0){
                if(parseInt(total_spins) >= 99){
                    total_spin_html = '99+';
                }else{
                    total_spin_html = 'x'+total_spins;
                }
                self.nextFreeSpin = '<span class="free-spin-text d-flex flex-column justify-content-center"><span>FREE</span><span>SPIN</span></span><span class="spin-value-text">'+total_spin_html+'</span>';
                self.spinFlag = true
            }else{
                if(datetime != null){
                    var availableSeconds = moment.duration(datetime).asSeconds()
                    setInterval(function time(){
                        availableSeconds -= 1;
                        self.nextFreeSpin = `<span style="padding-right: 7px;">${moment.utc(availableSeconds*1000).format('HH:mm:ss')}</span>`;
                    }, 1000);
                    self.spinFlag = false
                }else{
                    self.spinFlag = false
                    self.nextFreeSpin = `<span style="padding-right: 7px;">No Spins</span>`;
                }

            }
            
        },
        getLastSpinWheelId(){
            var self = this;
            api
            .getLastSpinWheelId()
            .then((response) => {
                this.lastSpinWheelId = response.data
            })
            .catch(function (error) {
                
            });
        },
    },
    watch: {
    '$online': {
      handler(val) {
        let self = this;
        self.isOnline = self.$online;
      },
      deep: true,
    }
  },
    
}
</script>

<style>
.rw-timmer-chip{
    background: #e7e7e7;
    color: black;
    padding: 0px 0px;
    border-radius: 15px;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
}
.rw-timmer-chip:hover{
    color: black;
    text-decoration: none;
}


.rw-timer-modal{
  background-color: #ffffff;
}
#rw-timer-modal___BV_modal_body_{
  background-color: #ffffff;
  border-radius: 6px;
  padding: 50px 50px 40px 50px;
  position: relative;
}
#rw-timer-modal___BV_modal_content_{
  background-color: #ffffff;
  border-radius: 6px;
  margin: 10px;
}

.rw-timer-modal-body{
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
}
.modal-wheel-layer{
    position: absolute;
    top: -117px;
    background: white;
    padding: 17px;
    border-radius: 100%;
}
.rw-modal-timer{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}
.rw-modal-text{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.st0{fill:#dbdbdb;}
.st1{fill:#0DD668;}
.st2{fill:#D3FCC8;}
.st3{fill:#007EE5;}
.st4{fill:#29576C;}
.rw-modal-close-btn{
    border: 0;
    padding: 17px 30px;
    background: #089338;
    color: white;
    font-weight: bold;
    border-radius: 30px;
    font-size: 19px;
    width: 70%;
}
.free-spin-text{
    color: #007EE5;
    font-size: 11px;
    line-height: 11px;
    padding: 3px;
    font-weight: 700;
}
.spin-value-text{
    background-color: #089338;
    color: #fff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    margin-left: 7px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and ( min-width:280px) and ( max-width:700px) {
    .rw-timmer-chip svg{
        width: 18px;
        height: 18px;
    }
    .free-spin-text{
        font-size: 8px;
        line-height: 8px;
        font-weight: 700;
    }
    .spin-value-text{
        width: 18px;
        height: 18px;
        font-size: 9px;
    }
}

@media only screen and ( min-width:320px) and ( max-width:700px) {
    .rw-timmer-chip svg{
        width: 25px;
        height: 25px;
    }
    .free-spin-text{
        font-size: 9px;
        line-height: 9px;
        font-weight: 700;
    }
    .spin-value-text{
        width: 25px;
        height: 25px;
        font-size: 9px;
    }
}

@media only screen and ( min-width:376px) and ( max-width:800px) {
    .rw-timmer-chip svg{
        width: 30px;
        height: 30px;
    }
    .free-spin-text{
        font-size: 11px;
        line-height: 11px;
        font-weight: 700;
    }
    .spin-value-text{
        width: 30px;
        height: 30px;
        font-size: 11px;
    }
}
</style>