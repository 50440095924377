<template> 
<div>
  <div v-if="isLoading">
    <CanPayLoader/>
  </div>
    <div>
      <b-modal
        ref="account-no-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        id="account-no-modal"
        centered
      >
        <div class="color">
          <div class="col-12 text-center">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="120"
              height="120"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125"
              xml:space="preserve"
              fill="#e14343"
            >
              <path
                d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
              />
            </svg>
          </div>
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                Important: Your bank provided an incomplete account number.
                Please enter your bank account number below.
              </label>
            </div>
            <br />
            <br />
            <div class="row mb-2">
              <div class="col-12">
                <span><b>Routing Number: </b>{{ routing_no }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    autocomplete="nope"
                    style="padding-left: 1rem !important"
                    v-model="account_no"
                    inputmode="numeric"
                    placeholder="Account Number (Numbers Only)"
                    type="text"
                    @keypress="isNumber($event)"
                    maxlength="30"
                    minlength="5"
                  />
                </div>
              </div>
            </div>
            <div class="row text-center mt-3">
              <div class="col-12">
                <button
                  type="button"
                  class="btn-black btn-verify"
                  @click="updateAccountNumber()"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
</div>
    
</template>
<script>
import accountAPI from "../../api/account.js";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
  export default {
    name: "IncompleteAccountNumberModal",
    components: {
      Loading,
      CanPayLoader
    },
    data() {
        return {
          isLoading:false,
          routing_no:'',
          account_no:'',
          currentUser:{}
        };
    },
    methods: {
      isNumber: function (evt) {
        const regex = /[0-9]/g;
        evt = evt ? evt : window.event;
        var inputKey = String(evt.key);
        if (!inputKey.match(regex)) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      showAccountNoModal() {
        this.$refs['account-no-modal'].show();
        this.currentUser = localStorage.getItem("consumer_login_response")
            ? JSON.parse(localStorage.getItem("consumer_login_response"))
            : null;
        this.routing_no = this.currentUser.routing_no;
        this.account_no = '';
      },
      updateAccountNumber() {
        var self = this;
        if (self.account_no === "" || self.account_no.length < 5) {
          alert("Please enter a valid bank account number.");
          return false;
        }
        self.isLoading = true;
        var request = {
          account_no: self.account_no,
        };
        accountAPI
          .updateAccountNumber(request)
          .then((response) => {
            if (response.code == 200) {
              self.$refs['account-no-modal'].hide();
              response.data.routing_no = undefined;
              localStorage.setItem(
                "consumer_login_response",
                JSON.stringify(response.data)
              );
              if (self.page_name == "pay") {
                self.$parent.last_four_account_no = response.data.account_no;
                self.$parent.currentUser = response.data;
                self.$parent.getReturnTransactions();
              } else {
                self.$parent.afterRealAccountNumberCheck(true);
              }
              self.isLoading = false;
            }
          })
          .catch(function (error) {
            setTimeout(() => {
              self.routing_no = self.currentUser.routing_no;
              self.isLoading = false;
            }, 500);
          });
      },
    }, 
    props: [  
      'page_name'  
    ],
    mounted: function () {
    }
  };
</script>
<style lang="scss">
  #account-no-modal___BV_modal_body_ {
    background-color: #ffffff;
    border-radius: 12px;
    margin: 10px;
  }
</style>